import React, { useState } from 'react';
import { withRemoteConfig } from '../firebase/RemoteConfig';
import { Row, Col, Jumbotron, Button, ButtonGroup } from 'react-bootstrap';
import { StyledProgressBar } from '../styled-components/StyledProgressBar';

export default withRemoteConfig(function ComingSoon({ landing_page_heading }) {
	const currentProgress = 34;
	const increment = 10;
	const [progress, setProgress] = useState(currentProgress);

	const decreaseProgress = () => {
		setProgress(progress >= increment ? progress - increment : 0);
	};

	const increaseProgress = () => {
		setProgress(progress <= 100 - increment ? progress + increment : 100);
	};

	const resetProgress = () => {
		setProgress(currentProgress);
	};

	const isMin = progress === 0;
	const isMax = progress === 100;

	return (
		<React.Fragment>
			<Row>
				<Col>
					<Jumbotron>
						<h1 className="display-4">{landing_page_heading}</h1>
						<p className="lead">I'm currently in the progress of developing my first website</p>
						<hr className="my-4" />
						<p>Please check back in some time though, I might have updated a few things by then.</p>
					</Jumbotron>
				</Col>
			</Row>
			<Row>
				<Col sm={3}>
					<p>A little indication for my progress:</p>
				</Col>
				<Col>
					<StyledProgressBar animated now={progress} />
				</Col>
			</Row>
			<hr className="my-4" />
			<Row>
				<Col>
					<p>If you want, you can increase or decrease my progress bar value. How fun?</p>
				</Col>
				<Col>
					<ButtonGroup>
						<Button
							size="sm"
							variant="outline-secondary"
							onClick={() => decreaseProgress()}
							disabled={isMin}
						>
							-{increment}
						</Button>
						<Button
							size="sm"
							variant="outline-secondary"
							onClick={() => increaseProgress()}
							disabled={isMax}
						>
							+{increment}
						</Button>
						<Button size="sm" variant="outline-secondary" onClick={() => resetProgress()}>
							Set back to &#8776; current progress
						</Button>
					</ButtonGroup>
				</Col>
			</Row>
		</React.Fragment>
	);
});
