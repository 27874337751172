import React from 'react';
import { Row, Col } from 'react-bootstrap';

export default function About() {
	return (
		<Row>
			<Col>
				<h1 className="display-5">About</h1>
				<p className="lead">A little bit more about myself</p>
				<hr className="my-4" />
				<p>I promise, I will write this section one day.</p>
			</Col>
		</Row>
	);
}
