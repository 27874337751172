import { Navbar, Image } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledNavbarBrand = styled(Navbar.Brand)`
	font-family: 'Fugaz One', cursive, var(--font-family-sans-serif);
`;

export const StyledNavbarBrandImage = styled(Image)`
	margin-right: 0.8125rem;
`;
