import * as firebaseApp from 'firebase/app';
import 'firebase/remote-config';

const firebaseConfig = {
	apiKey: 'AIzaSyCypBCJ86ot06_eyXE3omw2LBncPrEwQqE',
	authDomain: 'fleur-dev.firebaseapp.com',
	databaseURL: 'https://fleur-dev.firebaseio.com',
	projectId: 'fleur-dev',
	storageBucket: 'fleur-dev.appspot.com',
	messagingSenderId: '822909391068',
	appId: '1:822909391068:web:878082dc536a2ec1f0e419',
};

export const firebase = firebaseApp.initializeApp(firebaseConfig);
