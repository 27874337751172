import React from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { StyledNavbarBrand, StyledNavbarBrandImage } from '../styled-components/StyledNavbar';
import { RouterNavLink } from './RouterNavLink';
import Logo from '../assets/logo.png';

export default function NavigationBar(props) {
	return (
		<Navbar {...props} bg="light" expand="lg" sticky="top">
			<LinkContainer exact to="/">
				<StyledNavbarBrand>
					<StyledNavbarBrandImage
						roundedCircle
						alt="Logo"
						src={Logo}
						width="30"
						height="30"
						className="d-inline-block align-top"
					/>
					Fleur.dev
				</StyledNavbarBrand>
			</LinkContainer>
			<Navbar.Toggle aria-controls="basic-navbar-nav" />
			<Navbar.Collapse id="basic-navbar-nav">
				<Nav className="mr-auto">
					<RouterNavLink exact to="/">
						Home
					</RouterNavLink>
					<RouterNavLink to="/about">About</RouterNavLink>
					<NavDropdown title="Projects" id="nav-dropdown">
						<LinkContainer exact to="/projects/this-website">
							<NavDropdown.Item>This website</NavDropdown.Item>
						</LinkContainer>
						<LinkContainer exact to="/projects/api">
							<NavDropdown.Item>API</NavDropdown.Item>
						</LinkContainer>
						<NavDropdown.Item href="https://cupofhotcocoa.com" target="_blank" rel="noopener noreferrer">Cup of Hot Cocoa</NavDropdown.Item>
					</NavDropdown>
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	);
}
