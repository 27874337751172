import React, { useState } from 'react';
import { Row, Col, Card, Button } from 'react-bootstrap';
import { StyledCard, StyledCardBody, StyledCardHeader } from '../styled-components/StyledCard';
import { Center } from '../styled-components/Center';

export default function ThisWebsite() {
	const [featuredCardHovered, setFeaturedCardHovered] = useState(false);

	return (
		<Row>
			<Col>
				<h1 className="display-5">This website</h1>
				<p className="lead">What am I doing with it?</p>
				<hr className="my-4" />
				<p>
					I've started this website because I wanted to work on a fun project and learn new things
					at the same time. I've had some previous experience with simple web development, but over
					the past couple of months, I've learned to use new frameworks and tools. For this website,
					I'm using React, which is a front-end framework, and I am using Firebase for hosting.
					Hosting with Firebase has turned out to be much easier than I expected!
				</p>
				<p>
					I am hosting my code on a web-based repository and have set up CI to deploy my website
					every time I push to the master branch.
				</p>
				<p>
					I am also using Firebase's Remote Config to render parameters conditionally. In my case, I
					am using a (very exciting) parameter of <code>landing_page_heading</code> which displays <i>Coming
					soon!</i> if you are in based in the U.K. but displays <i>Coming soon</i> if you are from any
					other part of the world.
				</p>
				<p>
					To make my integration with Firebase Remote Config easier in React, I've written a
					provider and a HOC component for it. If you are interested in what that looks like, I am
					sharing it publicly on GitHub.
				</p>
				<Center>
					<StyledCard className="text-center">
						<StyledCardHeader
							hovered={featuredCardHovered ? 1 : 0}
							onMouseEnter={() => setFeaturedCardHovered(true)}
							onMouseLeave={() => setFeaturedCardHovered(false)}
						>
							Featured
						</StyledCardHeader>
						<StyledCardBody>
							<Card.Title>Firebase Remote Config for React</Card.Title>
							<Card.Text>
								My Firebase Remote Config for React implementation. Feel free to make suggestions
								for improvement!
							</Card.Text>
							<Button
								as="a"
								variant="primary"
								href="https://github.com/fleurschepen/react-firebase-remote-config"
								target="_blank"
								rel="noopener noreferrer"
							>
								Check it out on GitHub
							</Button>
						</StyledCardBody>
					</StyledCard>
				</Center>
				<p>
					In the future, I would like to explore more of Firebase's options. I am planning to look
					at their Cloud Functions and Authentication functionality next.
				</p>
				<p>
					For styling, I am using Bootstrap and styled-components. It gives the website a
					(relatively) clean look, without too much work. Although I am planning to add more custom
					styled components, to make it look less generic.
				</p>
			</Col>
		</Row>
	);
}
