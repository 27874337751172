import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  @keyframes animate-gradient-background {
		0% {
			background-position: 200% 0;
		}
		to {
			background-position: 0 0;
		}
	}
	
  div#root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
`;
