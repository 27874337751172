import React from 'react';
import { Row, Col } from 'react-bootstrap';

export default function NotFound() {
	return (
		<Row>
			<Col>
				<h1 className="display-5">Oops</h1>
				<p className="lead">Something went wrong :-(</p>
				<hr className="my-4" />
				<p>Try checking the URL again. The page you're trying to visit may not exist...</p>
			</Col>
		</Row>
	);
}
