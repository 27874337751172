import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { withRemoteConfig } from './firebase/RemoteConfig';
import './style/custom.scss';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from './style/GlobalStyle';
import NavigationBar from './components/NavigationBar';
import { StyledContainer } from './styled-components/StyledContainer';
import ComingSoon from './pages/ComingSoon';
import About from './pages/About';
import ThisWebsite from './pages/ThisWebsite';
import API from './pages/API';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import NotFound from './pages/NotFound';
import Footer from './components/Footer';

export default withRemoteConfig(function App(/*{theme}*/) {
	return (
		<ThemeProvider theme={{}}>
			<GlobalStyle />
			<Router>
				<NavigationBar />
				<StyledContainer className="my-5">
					<Switch>
						<Route exact path="/">
							<ComingSoon />
						</Route>
						<Route path="/about">
							<About />
						</Route>
						<Route path="/projects/this-website">
							<ThisWebsite />
						</Route>
						<Route path="/projects/api">
							<API />
						</Route>
						<Route path="/privacy-policy">
							<PrivacyPolicy />
						</Route>
						<Route path="/terms-of-service">
							<TermsOfService />
						</Route>
						<Route>
							<NotFound />
						</Route>
					</Switch>
				</StyledContainer>
				<Footer />
			</Router>
		</ThemeProvider>
	);
});
