import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { StyledFooter } from '../styled-components/StyledFooter';

export default function Footer(props) {
	return (
		<StyledFooter {...props} className="px-3 px-sm-4 px-md-5 py-4 bg-light">
			<Row>
				<Col>
					<ul className="list-inline">
						<li className="float-lg-right float-md-right">
							<a href="#top">Back to top</a>
						</li>
						<li className="list-inline-item">
							<Link to="/privacy-policy">Privacy Policy</Link>
						</li>
						<li className="list-inline-item">
							<Link to="/terms-of-service">Terms of Service</Link>
						</li>
					</ul>
					<p>&copy; Copyright {new Date().getFullYear()} &mdash; Fleur Schepen</p>
				</Col>
			</Row>
		</StyledFooter>
	);
}
