import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { RemoteConfigProvider } from './firebase/RemoteConfig';
import * as serviceWorker from './serviceWorker';
import App from './App';

const remoteConfigSettings = {
	minimumFetchIntervalMillis: 3600000,
};

const remoteConfigDefaultConfig = {
	landing_page_heading: 'Coming soon',
};

function render() {
	return (
		<RemoteConfigProvider settings={remoteConfigSettings} defaultConfig={remoteConfigDefaultConfig}>
			<App />
		</RemoteConfigProvider>
	);
}

ReactDOM.render(render(), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
