import { ProgressBar } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledProgressBar = styled(ProgressBar)`
	margin-bottom: 1rem;

	.progress-bar {
		background-size: 200%;
		background-color: ${({ now }) => now <= 33 && 'var(--danger)'}
			${({ now }) => now > 33 && now <= 66 && 'var(--warning)'}
			${({ now }) => now > 66 && 'var(--success)'};
		transition: all 0.3s ease;
		background-image: linear-gradient(
			90deg,
			transparent 0%,
			rgba(255, 255, 255, 0.5) 50%,
			transparent 100%
		);
		background-blend-mode: soft-light;
		animation: animate-gradient-background 1.1s infinite;
	}
`;
