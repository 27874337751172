import React from 'react';
import { Row, Col } from 'react-bootstrap';

export default function API() {
	return (
		<Row>
			<Col>
				<h1 className="display-5">API</h1>
				<p className="lead">Documentation coming soon (although, it might not)</p>
				<hr className="my-4" />
				<p>
					I am exploring Firebase functions to develop a simple (read: apart from the gained
					experience, useless) API. I am using Firebase hosting to deploy my API on the URL{' '}
					<a href="https://api.fleur.dev/v1/" target="_blank" rel="noopener noreferrer">
						https://api.fleur.dev/v1/
					</a>
					. I only have one endpoint at the moment, which is an endpoint for <code>name</code> (
					<code>/v1/name</code>). You can{' '}
					<a href="https://api.fleur.dev/v1/name" target="_blank" rel="noopener noreferrer">
						try it out for yourself
					</a>{' '}
					and find out what exiting results you'll get.
				</p>
			</Col>
		</Row>
	);
}
