import { Card } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledCard = styled(Card)`
	max-width: 500px;
	display: inline-block;
	margin-bottom: 1.875rem;
	border-top: none;
	border-left: none;
	border-right: none;
`;

export const StyledCardHeader = styled(Card.Header)`
	background-size: 190%;
	background-color: var(--primary);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-family: 'Fugaz One', cursive, var(--font-family-sans-serif);
	text-transform: uppercase;
	background-image: ${({ hovered }) =>
		hovered === 1 &&
		'linear-gradient(90deg, transparent 0%, rgba(255, 255, 255, .5) 90%, transparent 100%);'};
	background-blend-mode: ${({ hovered }) => hovered === 1 && 'soft-light'};
	animation: ${({ hovered }) => hovered === 1 && 'animate-gradient-background 2s'};
`;

export const StyledCardBody = styled(Card.Body)`
	padding: 1.875rem 1.25rem;
`;
